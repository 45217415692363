import { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Linking,
  Share,
  Animated,
  ImageBackground,
  SafeAreaView,
  ActivityIndicator,
} from "react-native";
import { Button,  Chip, Tab, Divider, Image, Dialog  } from "@rneui/themed";
import { WebView } from "react-native-web-webview";
import * as Clipboard from "expo-clipboard";
import Icon from "react-native-vector-icons/Ionicons";
import { ScrollView } from "react-native-gesture-handler";
const url =
  "https://vw2nw.com/RA/NjQ0MTA2MDI0NjQyMGVlODNiYzgwZDRi";
const loginUrl = "https://vw2nw.com/";
const playStoreUrl = "https://play.google.com/store/apps/details?id=com.vWorld.vWorld&hl=en";
const webUrl = "https://hj888.v-world2.co";

const HomeWebScreen = ({}) => {
  const [index, setIndex] = useState(2);
  const animated = useRef(new Animated.Value(500)).current;
  const duration = 1200;
  const [visible, setVisible] = useState(false);
  const [year, setYear] = useState(new Date().getFullYear());
  const [animatedTo, setAnimatedTo] = useState(Dimensions.get('screen').height / 2.2);
  const [lang, setLang] = useState("en");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [alertDialogVisible, setAlertDialogVisible] = useState(false);
  const [dialogLink, setDialogLink] = useState("");

  useEffect(() => {
    Animated.sequence([
      Animated.timing(animated, {
        toValue: animatedTo,
        duration: duration,
        useNativeDriver: true,
      }),
    ]).start();
  }, [animated, animatedTo]);


  const openWhatsapp = () => {
    let url = "https://api.whatsapp.com/send?phone=601162312031&text=Hi! I get your contact from V World 2.0";
    Linking.openURL(url)
      .then((data) => {
        console.log("WhatsApp Opened successfully " + data); //<---Success
      })
      .catch(() => {
        alert("Make sure WhatsApp installed on your device"); //<---Error
      });
  };

  const openLink = (link) => {
    Linking.openURL(link);
  }

  const onCopy = async (link) => {
    await Clipboard.setStringAsync(link);
    setDialogVisible(false);
    setAlertDialogVisible(true);
    setDialogLink(link);
  }


  const updateTab = (e) => {
    setIndex(e);
    if(e === 0 || e === 1){
      setAnimatedTo(10);
    }else{
      setAnimatedTo(Dimensions.get('screen').height / 2);
    }
  }

  const changeLang = () =>{
    lang === 'en' ? setLang("ch") : lang === 'ch' ? setLang("malay") : setLang("en");
  }

  const onShare = () => {
    setDialogVisible(true);
  }


  return (
    <SafeAreaView style={styles.bgContainer}>
        <ImageBackground
          source={require("../images/vWorldBg.png")}
          resizeMethod="resize"
          resizeMode="cover"
          style={{
            flex: 1,
            justifyContent: "center",
            height: Dimensions.get("screen").height,
          }}
        >
           <Icon name="share-social-outline" 
              color={"#fff"}
              size={25}
              style={{ textAlign: 'right', marginRight: 15, marginTop: 50 }}
              onPress={() => onShare()}/>
          <Animated.View
            style={[
              { transform: [{ translateY: animated }] },
              styles.container,
            ]}
          >
             <Dialog
              isVisible={dialogVisible}
              onBackdropPress={()=> setDialogVisible(!dialogVisible)}
              overlayStyle={{ minWidth: '45%', maxWidth: 300 }}
            >
              <Dialog.Title title="Share"/>
                  <View style={styles.imageContainer} >
                    <View style={{ width: '50%', alignItems: 'center', marginBottom: 15 }}>
                      <Icon.Button name="link" backgroundColor="#f0f4f7" color={"#8195b4"} 
                          style={{ width: 150, height: 40, justifyContent: 'center' }}
                          onPress={() => onCopy(webUrl)} >
                        <Text style={{ fontFamily: 'PTSerif_700Bold', fontSize: 15, color: '#8195b4', textAlign: 'center' }}>
                          Copy Link
                        </Text>
                      </Icon.Button>
                    </View>
                    <View style={{ width: '50%', alignItems: 'center' }}>
                      <Icon.Button name="logo-android" backgroundColor="#f0f4f7" color={"#8195b4"} 
                          style={{ width: 150, height: 40, justifyContent: 'center' }}
                          onPress={() => onCopy(playStoreUrl)} >
                        <Text style={{ fontFamily: 'PTSerif_700Bold', fontSize: 15, color: '#8195b4', textAlign: 'center' }}>
                          Google Play
                        </Text>
                      </Icon.Button>
                      {/* <Image onPress={()=> onCopy(playStoreUrl)} source={ require('../images/googlePlayBadge.png') } 
                        style={styles.image} resizeMode="center"></Image> */}
                    </View>
                  </View>
              </Dialog>
            <Dialog
              isVisible={alertDialogVisible}
              onBackdropPress={()=> setAlertDialogVisible(!alertDialogVisible)}
              overlayStyle={{ minWidth: '45%', maxWidth: 300 }}
            >
              <Dialog.Title title="Copied!"/>
              <Text>You can share to your friends with this link!</Text>
              <Text style={{ textDecorationLine: 'underline', color: 'blue' }}>{dialogLink} </Text>
            </Dialog>
            <Tab value={index} onChange={(e)=> updateTab(e)} dense 
              animationType="spring"
              titleStyle={{ color: '#000' }}
              indicatorStyle={{
                backgroundColor: '#000',
              }}>
              <Tab.Item>Login</Tab.Item>
              <Tab.Item>Register</Tab.Item>
              <Tab.Item>
                About us
              </Tab.Item>
            </Tab>
            {
            index === 0 ? (
              <View style={{ height: '75%', minHeight: 600,  maxWidth: 768 }}>
                <WebView
                  source={{ uri: loginUrl }}
                  startInLoadingState={true}
                  onLoadStart={() => setVisible(true)}
                  onLoad={() => setVisible(false)}
                />
              </View>
            ) : 
            index === 1 ? (
              <WebView
                source={{ uri: url }}
                startInLoadingState={true}
                onLoadStart={() => setVisible(true)}
                onLoad={() => setVisible(false)}
                style={{
                  flex: 1,
                  height: '100%',
                  maxWidth: 768,
                }}
              />
            ) : 
            (
              <>
              <View style={{ paddingHorizontal: 15 }}>
                  <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                    <Text
                     style={{
                      fontFamily: 'PTSerif_700Bold',
                      textDecorationLine: 'underline'
                    }}
                    >
                      {
                        lang === 'en' ?
                          'About V World 2.0'
                        : lang === 'ch' ?
                          '关于 V World 2.0'
                        : 'Tentang V World 2.0'
                      }

                    </Text>
                    <Chip
                      title={
                        lang === 'en' ?
                        'English'
                      : lang === 'ch' ?
                        'Chinese'
                      : 'Malay'
                      }
                      icon={{
                        name: 'language-outline',
                        type: 'ionicon',
                        size: 15,
                      }}
                      onPress={() => changeLang()}
                      type="outline"
                      iconRight
                      containerStyle={{ textAlign: 'center'}}
                    />
                  </View>
                 
                  <Text
                    style={{
                      fontFamily: 'PTSerif_400Regular',
                    }}>
                      {
                         lang === 'en' ?
                         `Vworld2.0 game platform is a professional game creation and social platform, providing game developers and players with rich resources and tools to help them realize their creativity and dreams in the game world\n\nIn Vworld2. 0 game platform, you can find a rich library of game resources, including various exquisite characters, scenes, sound effects and special effects, etc., allowing you to easily create your own game world. The platform also provides a powerful game editor, which can create complex game logic and interactive experience without programming experience, making your creation process easier and more flexible\n\nIn addition to creation tools, the Vworld2.0 game platform also provides The developers provide a wide range of social features to interact, communicate and collaborate with other developers. You can display your work on the platform and share your creations with players around the world. At the same time, the platform also supports multi-platform publishing, so that your game works can be played on different devices and platforms. \n\nAs a dynamic and innovative game community, the V-world 2.0 game platform adheres to the concept of openness, tolerance and creativity, creating a creative space full of infinite possibilities for game developers and players. \n`
                       : lang === 'ch' ?
                         `Vworld2.0 游戏平台是一款专业的游戏创作和社交平台，为游戏开发者和玩家提供了丰富的资源和工具，助力他们在游戏世界中实现自己的创意和梦想\n\n在 Vworld2.0游戏平台上，你可以找到丰富的游戏资源库，包括各种精美的角色、场景、音效和特效等，让你轻松打造属于自己的游戏世界。平台还提供了强大的游戏编辑器，无需编程经验，即可创建复杂的游戏逻辑和交互体验，让你的创作过程更加简便和灵活\n\n除了创作工具，Vworld2.0 游戏平台还为游戏开发者提供了广泛的社交功能，可以与其他开发者互动、交流和合作。你可以在平台上展示你的作品，并与全球的玩家分享你的创作成果。同时，平台还支持多平台发布，让你的游戏作品可以在不同的设备和平台上畅玩。\n\n作为一个充满活力和创新的游戏社区，V-world 2.0 游戏平台秉承着开放、包容和激发创意的理念，为游戏开发者和玩家创造了一个充满无限可能的创作空间。\n`
                       :`Platform permainan Vworld2.0 ialah penciptaan permainan profesional dan platform sosial, menyediakan pembangun permainan dan pemain dengan sumber dan alatan yang kaya untuk membantu mereka merealisasikan kreativiti dan impian mereka dalam dunia permainan\n\nDalam platform permainan Vworld2. 0, anda boleh temui perpustakaan yang kaya dengan sumber permainan, termasuk pelbagai watak indah, adegan, kesan bunyi dan kesan khas, dsb., membolehkan anda mencipta dunia permainan anda sendiri dengan mudah. Platform ini juga menyediakan editor permainan yang berkuasa, yang boleh mencipta logik permainan yang kompleks dan pengalaman interaktif tanpa pengalaman pengaturcaraan, menjadikan proses penciptaan anda lebih mudah dan lebih fleksibel\n\nSelain alat penciptaan, platform permainan Vworld2.0 juga menyediakan Pembangun menyediakan pelbagai ciri sosial untuk berinteraksi, berkomunikasi dan bekerjasama dengan pembangun lain. Anda boleh memaparkan hasil kerja anda pada platform dan berkongsi ciptaan anda dengan pemain di seluruh dunia. Pada masa yang sama, platform ini juga menyokong penerbitan berbilang platform, supaya karya permainan anda boleh dimainkan pada peranti dan platform yang berbeza. \n\nSebagai komuniti permainan yang dinamik dan inovatif, platform permainan V-world 2.0 mematuhi konsep keterbukaan, toleransi dan kreativiti, mewujudkan ruang kreatif yang penuh dengan kemungkinan yang tidak terhingga untuk pembangun dan pemain permainan. \n`
                      }
                  </Text>
                 <Divider style={{ paddingVertical: 15 }}/>
                 <Button
                    buttonStyle={{
                      backgroundColor: "#25d366",
                      borderRadius: 20,
                    }}
                    containerStyle={{
                      marginHorizontal: 50,
                      marginTop: 50,
                    }}
                    onPress={() => openWhatsapp()}
                  >
                    <Icon name="logo-whatsapp" type='ionicon' size={20} color={"#fff"}/>
                    <Text style={{fontWeight: "bold", fontFamily: "PTSerif_400Regular", color: '#fff', marginLeft: 10}}>Whatsapp</Text>
                  </Button>
              </View>
             </> 
            )}
            <View style={{ marginTop: 10 }}>
                <Text style={{ textAlign: 'center' }}>Copyright {'\u00A9'} {year} HJ888 All Rights Reserved</Text>
                <Text style={{ textAlign: 'center' }}>Ver: 1.0.3</Text>
            </View>
          </Animated.View>
        </ImageBackground>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  bgContainer: {
    backgroundColor: "#fff",
    height: Dimensions.get("screen").height,
    margin: 'auto',
    maxWidth: 500,
    minWidth: 320,
    width: '100%'
  },
  container: {
    backgroundColor: "#fff",
    margin: 'auto',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
    height: Dimensions.get("screen").height,
    maxWidth: 500,
    minWidth: 320,
    width: '100%'
  },
  imageContainer: {
    width: '100%', 
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image:{
    width: 150,
    height: 150,
  },

});
export default HomeWebScreen;
