import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Platform
} from "react-native";
import HomeScreen from "./assets/screens/Home";
import HomeWebScreen from "./assets/screens/HomeWebScreen";
import * as Updates from 'expo-updates';
import { useEffect } from "react";
import {
  useFonts,
  PTSerif_400Regular,
  PTSerif_400Regular_Italic,
  PTSerif_700Bold,
  PTSerif_700Bold_Italic,
} from '@expo-google-fonts/pt-serif';

export default function App() {

  let [fontsLoaded] = useFonts({
    PTSerif_400Regular,
    PTSerif_400Regular_Italic,
    PTSerif_700Bold,
    PTSerif_700Bold_Italic,
  });

  useEffect(() => {
    checkForUpdate();
  },[]);
  const checkForUpdate = async () =>{
    Updates.addListener((event) => {
      if(event.type === Updates.UpdateEventType.UPDATE_AVAILABLE){
        // alert('Please restart');
        Updates.reloadAsync();
      }
    })
  }
  if(fontsLoaded){
    return (
      <>
         <StatusBar
            style="dark"
          />
        { 
          Platform.OS == 'web' || Platform.OS == 'windows' || Platform.OS == 'macos' ?
          <View>
            <HomeWebScreen />
          </View> 
          : <HomeScreen />
        }  
      </>
    );
  }else{
    return (
      <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size="large" />
      </View>
    );
  }
 
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  horizontal: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    padding: 10,
  },
});
